import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './styles/Nav.css'
import { ReactComponent as LOGO } from '../images/LOGO.svg';
import { useMediaQuery } from 'react-responsive'
import { HashLink } from 'react-router-hash-link';


function Nav() {

  const MobileNave = useMediaQuery({ query: '(max-width: 940px)' })
  const DesctopNav = useMediaQuery({ query: '(min-width: 940px)' })

  const [isActive, setActive] = useState(false);



  const handleToggle = () => {
    setActive(!isActive);
  };



  useEffect(() => {
    
    if (isActive) {
   

      document.body.setAttribute('style',`overflow: hidden;`)
      

    }
    
    return () => {


      document.body.setAttribute('style', 
      `overflow: unset;`)
    };
    

  }, [isActive]);

  const [click, setClick] = useState(false);
  const handleClick = (e) => {
    e.preventDefault()
    setClick(!click);
    handleToggle()
    

  }

  const closeMenu = () => {
    setClick(false);
    handleToggle();
    window.scroll(0, 0)
  }

  const gotTop = () => {
    window.scroll(0, 0)
  }

  return (
    <div className="header">
      <nav className="navbar">
        <HashLink to='/'>
          <LOGO className="logo" />
        </HashLink>

        {MobileNave &&
          <div className="hamburger" onClick={handleClick}>
            <div className={click ? "list-container active" : "list-container"}>
              <button className="more-button" aria-label="Menu Button" onClick={handleClick}>
                <div className="menu-icon-wrapper">
                  <div className="menu-icon-line half first"></div>
                  <div className="menu-icon-line"></div>
                  <div className="menu-icon-line half last"></div>
                </div>
              </button>
            </div>

          </div>
        }
        {DesctopNav && <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <HashLink  to="/" onClick={gotTop}>Home</HashLink>
          </li>
    
          <li className="nav-item">
            <Link  to="activities" onClick={gotTop}>Activities</Link>
            {/*    */}
          </li>
          <li className="nav-item">
            <Link  to="gallery" onClick={gotTop}>Gallery</Link>
            {/*    */}
          </li>
          <li className="nav-item">
            <HashLink  to="/#contact-us" onClick={gotTop}>Contact Us</HashLink>
          </li>
        </ul>}



        {MobileNave && <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <HashLink to='/' onClick={closeMenu}>Home</HashLink>
          </li>
          <li className="nav-item">
            <Link to="activities" onClick={closeMenu}>Activities</Link>
          </li>
          <li className="nav-item">
            <Link to='gallery' onClick={closeMenu}>Gallery</Link>
          </li>
          <li className="nav-item">
            <HashLink to='/#contact-us' onClick={closeMenu}>Contact Us</HashLink>
          </li>
        </ul>}



      </nav>
    </div>
  )
}

export default Nav
