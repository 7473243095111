//  import React, { useEffect, useState} from "react";
// import axios from "axios";
// import { Route, Routes } from "react-router-dom";
// import Nav from "./components/Nav";
// import "./components/styles/SvgAnimation.css";
// import Activities from "./components/pages/Activities";
// import Snorkeling from "./components/pages/activities/Snorkeling";
// import IslandVisit from "./components/pages/activities/IslandVisit";
// import InBoat from "./components/pages/activities/InBoat";
// import Footer from "./components/pages/Footer";
// import Loading from "./components/Loading";
// import Layout from "./components/Layout";
// import NotFound from "./components/pages/NotFound";

// function App() {

//   const [loading, setLoading] = useState(true)


//   useEffect(() => {
//     fetchData()
//   }, []);




//   const fetchData = async () => {

//     try {
//       const { data: response } = await axios.get(
//         `https://jmaldives-back.herokuapp.com/api/boat-and-cabin?populate[0]=boat_photoes&populate=cabin_infos.cabin_photoes&populate=*`
//       );
//       const dataArr = response
//       setTimeout(() => {
//         setLoading(false)
//       }, 1000)

//     } catch (error) {
//       console.log(error.message);
//     }



//   };


//   return (
//     <>

//       {loading ?
//         <Loading />
//         :

//         <>

//           <Nav />


//           <Routes>

//             <Route path='/' element={<Layout />}></Route>

//             <Route path='activities' element={<Activities />}>
//               <Route index element={<Snorkeling />} />
//               <Route path='snorkeling' element={<Snorkeling />} />
//               <Route path='island-visit' element={<IslandVisit />} />
//               <Route path='in-boat' element={<InBoat />} />
//             </Route>


//             <Route path='*' element={<NotFound />}></Route>


//           </Routes>

//           <Footer />



//         </>

//       }


//     </>
//   );
// }

// export default App;




// ///////////////later will need when sanity schema finish

// import React, { useState, useEffect } from 'react';


// export default function App() {

//   const [abouts, setAbouts] = useState([]);

// useEffect(() => {
//   const query = '*[_type == "homePage"]';

//   client.fetch(query).then((data) => {
//     setAbouts(data);
//   });
// }, []);
// console.log(abouts)

//   return (
//     <div>
//      {abouts.map((about, index) => (
//           <div key={index}>
//             <img src={urlFor(about.imgUrl)} alt={about.title} />
//             {/* <h2 className="bold-text" style={{ marginTop: 20 }}>{about.name}</h2> */}
//             <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
//           </div>
//         ))}
//     </div>
//   )
// }






import React, { Suspense }  from "react";
import { Route, Routes } from "react-router-dom";
import "./components/styles/SvgAnimation.css";
import "./components/styles/Activities.css"
import "./components/styles/Gallery.css"
import Nav from "./components/Nav";




const Activities = React.lazy(() => import('./components/pages/Activities'));
const Snorkeling = React.lazy(() => import('./components/pages/activities/Snorkeling'));
const IslandVisit = React.lazy(() => import('./components/pages/activities/IslandVisit'));
const InBoat = React.lazy(() => import('./components/pages/activities/InBoat'));
const Footer = React.lazy(() => import('./components/pages/Footer'));
const Loading = React.lazy(() => import('./components/Loading'));
const Layout = React.lazy(() => import('./components/Layout'));
const NotFound = React.lazy(() => import('./components/pages/NotFound'));
const Gallery = React.lazy(() => import('./components/pages/Gallery'));
const PhotoG = React.lazy(() => import('./components/pages/gallery/PhotoG'));
const VideoG = React.lazy(() => import('./components/pages/gallery/VideoG'));




function App() {

 
  return (
    <>

  

          <Nav />
          <Suspense fallback={<Loading />}>
          <section>
         <Routes>

             <Route path='/' element={<Layout />}></Route>

             <Route path='activities' element={<Activities />}>
             <Route index element={<Snorkeling />} />
             <Route path='snorkeling' element={<Snorkeling />} />
               <Route path='island-visit' element={<IslandVisit />} />
               <Route path='in-boat' element={<InBoat />} />
             </Route>

              <Route path='gallery' element={<Gallery />}>
              <Route index element={<PhotoG />} />
               <Route path='photo' element={<PhotoG />} />
               <Route path='video' element={<VideoG />} />
              </Route>

            <Route path='*' element={<NotFound />}></Route>


          </Routes>
          </section>
          </Suspense>


          <Footer />



       


    </>
  );
}

export default App;